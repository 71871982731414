import React from "react";
import { Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import "../slick_board/slick_board.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SlickBoard = ({ slickSettings, list }) => {
    return (
        <div className="slick-board slick-container p-3 pt-0 p-md-4 pt-md-0 p-lg-5 pt-lg-0">
            <Slider {...slickSettings}>
                {list.map((item, key) => {
                    let slickDescription = [];
                    let slickText = [];
                    if (item.description.includes("\n")) {
                        slickDescription = item.description.split("\n");
                    }
                    if (item.text.includes("\n")) {
                        slickText = item.text.split("\n");
                    }

                    return (
                        <div className="slick-item-container" key={key}>
                            <Row className="gx-3 gx-md-4 gx-lg-4 pb-3 pb-lg-4">
                                <Col className="col-board-photo board-photo">
                                    <img alt="item" src={item.image} width="212" height="212"  />
                                    <div  className="mt-4">
                                    <h3 className="board-member">
                                        <span className="board-name">{item.name}</span>
                                        <br />
                                        <span className="board-position">{item.position}</span>
                                    </h3>
                                    </div>
                                </Col>
                                <Col className="col col-board-txt board-texto">
                                    <div  className="board-highlighted">

                                        {slickDescription.length > 0 ? (
                                            // <p className="highlightsbold">
                                            <p style={{margin: '3rem 0 0 0'}}>
                                                {slickDescription.map((phrase) => {
                                                    return (
                                                        <>
                                                            {phrase} <br />
                                                        </>
                                                    );
                                                })}
                                            </p>
                                        ) : (
                                            <p style={{margin: '3rem 0 0 0'}}>{item.description}</p>
                                        )}
                                   </div>
                                    {/* <div className="board-texto">*/}
                                            {slickText.length > 0 ? (
                                                <p>
                                                    {slickText.map((phrase) => {
                                                        return (
                                                            <>
                                                                {phrase} <br />
                                                            </>
                                                        );
                                                    })}
                                                </p>
                                            ) : (
                                                <p>{item.text}</p>
                                            )}
                                    {/*</div>*/}
                                </Col>
                                {/*<Col xs={12} md={5} className="board-texto">
                                    {slickText.length > 0 ? (
                                        <p>
                                            {slickText.map((phrase) => {
                                                return (
                                                    <>
                                                        {phrase} <br />
                                                    </>
                                                );
                                            })}
                                        </p>
                                    ) : (
                                        <p>{item.text}</p>
                                    )}
                                </Col>*/}
                            </Row>
                        </div>
                    );
                })}
            </Slider>
        </div>
    );
};

export default SlickBoard;
